<script lang="ts" setup>
import { isFilterClickAway } from 'lib/filters'

const { $t } = useNuxtApp()

const el = ref(null)
const showErrorMessage = ref(false)
const {
  filters,
  loadingFacets,
  applyFilter,
  selectedKeys,
  toggleDropdown,
  isExpanded,
  hide: hideFilters,
  clear,
  clearSingleFilterGroup,
} = useFilterMenu()

// watch loadingFacets if it takes more than 100ms to load show Loading spinner
const showLoadingSpinner = ref(false)
let timeoutId: ReturnType<typeof setTimeout> | null = null
watch(loadingFacets, (val) => {
  if (timeoutId !== null)
    clearTimeout(timeoutId)

  if (val) {
    timeoutId = setTimeout(() => {
      if (loadingFacets.value)
        showLoadingSpinner.value = true
    }, 100)
  }
  else {
    showLoadingSpinner.value = false
  }
})

function hide(e: MouseEvent) {
  if (!isFilterClickAway(e))
    return

  hideFilters()
  // polite($t('hideFilter'))
}

onClickOutside(el, (e) => {
  if (loadingFacets.value)
    return
  hide(e)
})
</script>

<template>
  <div class="filter-menu-overlay" :class="{ 'filter-menu-overlay--active': loadingFacets }" />
  <aside
    ref="el"
    class="filter-menu"
    :class="{ 'filter-menu--loading': loadingFacets }"
    role="dialog"
    aria-modal="true"
    :aria-label="$t('filterMenuOptionsAria')"
  >
    <h1 class="title">
      {{ $t('filters') }}
      <div>
        <LoadingSpinner v-if="showLoadingSpinner" />
      </div>
    </h1>
    <IconBasic filename="close" @click="hide" />

    <p v-if="showErrorMessage" class="message">
      {{ $t('filterErrorMessage') }}
    </p>
    <div class="accordion-container">
      <FilterAccordion
        v-for="(filter, idx) in filters"
        :key="filter.id"
        :filter="filter"
        :focus="idx === 0"
        :active="selectedKeys.includes(filter.id)"
        :expanded="!isExpanded(filter.id)"
        @clear="clearSingleFilterGroup"
        @apply="applyFilter"
        @toggle="toggleDropdown"
      />
    </div>

    <div class="filter-actions">
      <ButtonBasic
        role="button"
        :aria-label="$t('clearAllFiltersAria')"
        size="small"
        design="simple"
        native-type="reset"
        :uppercase="false"
        data-test="clear-filter-button"
        @click="clear"
      >
        <span aria-hidden="true">{{ $t('clearAllFilters') }}</span>
      </ButtonBasic>
      <ButtonBasic
        id="accept-btn"
        role="button"
        :aria-label="$t('acceptFilterAria')"
        design="simple"
        size="small"
        :uppercase="false"
        background-color="var(--black)"
        data-test="close-filter-button"
        @click="hide"
      >
        <span aria-hidden="true"> {{ $t('filtersOk') }}</span>
      </ButtonBasic>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';
@import 'assets/scss/rules/edges';

.filter {
  border-bottom: 1px solid var(--divider-colored);
}

.title {
  @include body1;
  text-align: center;
  margin-right: var(--spacing-m);
  display: flex;
  gap: 0.5em;
}

.filter-menu {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: flex;
  flex-direction: column;
  background: var(--gray-lighter);
  padding: var(--spacing-m);

  &:after {
    content: '';
    position: absolute;
    background: inherit;
    top: -2rem;
    right: 0;
    width: 100%;
    height: 2rem;
    @include scallop;
  }

  &--loading {
    pointer-events: none;
  }

  .icon {
    width: 10px;
    height: 10px;
    position: absolute;
    top: var(--spacing-m);
    right: var(--spacing-m);
    cursor: pointer;
  }
}

.filter-menu-overlay {
  visibility: hidden;
  position: absolute;
  inset: 0;
  cursor: wait;
  z-index: 101;

  &--active {
    visibility: visible;
  }
}

.accordion-container {
  width: 100%;
  overflow-y: auto;
  padding-top: 4.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin-bottom: var(--spacing-m);

  /* Hide scrollbar for Chrome, Safari and Opera */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
  }

  @media (max-width: $phone) {
    height: 65vh;
  }
}

.filter-actions {
  bottom: 0;
  z-index: 3;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  & button {
    &:nth-of-type(2) span {
      color: var(--white);
    }

    span {
      @include body2;
    }
  }
}

.filter-actions > * {
  flex: 1;
}

.filter-actions #accept-btn {
  border-left: none;
  border-right: none;
}

.radio-group + .radio-group {
  margin-top: 5rem;
}

.message {
  z-index: 3;
  width: 100%;
  font-size: 1.4rem;
  padding: 0.5rem 2rem;
  background: var(--red);
  transition: linear 0.2s;
  border-bottom: 0.2rem solid var(--black);
}

@media (min-width: $phone) {
  .filter-menu {
    max-width: 54rem;
    padding: var(--spacing-m);

    &:after {
      width: 2rem;
      height: 100%;
      top: 0;
      right: -2rem;
      --mask: radial-gradient(11.89px at calc(100% - 16.8px) 50%, #000 99%, #0000 101%) 0
          calc(50% - 16px) / 100% 32px,
        radial-gradient(11.89px at calc(100% + 8.8px) 50%, #0000 99%, #000 101%) calc(100% - 8px)
          50%/100% 32px repeat-y;
      -webkit-mask: var(--mask);
      mask: var(--mask);
    }
  }

  .message {
    font-size: 1.6rem;
    padding: 1.2rem 2rem;
  }

  .title {
    text-align: left;
  }

  .accordion-container {
    padding-top: 7.2rem;
  }
}

@media (max-width: 620px) {
  .filter-menu {
    max-width: auto;
  }
}
</style>
