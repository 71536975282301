<script lang="ts" setup>
import type { FilterGroup, FilterItem, FilterMenuKeys } from '~/types/models/filter.model'

const props = defineProps<{
  filter: FilterGroup
  expanded: boolean
  active: boolean
}>()

const emit = defineEmits<{
  (e: 'toggle', filterKey: FilterMenuKeys): void
  (e: 'clear', filterKey: FilterMenuKeys): void
  (e: 'apply', filterKey: FilterMenuKeys, valueId: string, valueValue?: any): void
}>()

function mapFilterItemToOption(item: FilterItem, isParentDisabled = false) {
  return {
    id: item.id,
    value: Boolean(item.value),
    disabled: isParentDisabled || item.disabled,
  }
}

const labelKey = computed(() => props.filter.metadata?.translationKey || props.filter.id)

// Price Filter
const filterStore = useFilterStore()
const { maxPriceRange } = storeToRefs(filterStore)
function applyPriceFilter([min, max]: [number, number]) {
  emit('apply', 'price', props.filter.items[0].id, min)
  emit('apply', 'price', props.filter.items[1].id, max)
}
</script>

<template>
  <AccordionFilterBase
    v-if="filter.items.length > 0"
    class="filter"
    :expanded="expanded"
    :active="active"
    :title="$t(labelKey)"
    :data-test="`${filter.id}-dropdown`"
    @clear="$emit('clear', filter.id)"
    @toggle="$emit('toggle', filter.id)"
  >
    <template #dropdown-content>
      <FilterCheckGroupColors
        v-if="filter.id === 'color'"
        :options="filter.items.map((item) => mapFilterItemToOption(item, filter.disabled))"
        :filter-key="filter.id"
        class="slider-content"
        @apply="$emit('apply', filter.id, $event.id, $event.value)"
      />
      <FilterRangeSlider
        v-if="filter.id === 'price'"
        :min-value="Number(filter.items[0].value)"
        :max-value="Number(filter.items[1].value)"
        :max-range="maxPriceRange"
        @apply="(e: [number, number]) => applyPriceFilter(e)"
      />
      <FilterCheckGroup v-if="filter.id !== 'price' && filter.id !== 'color'" class="slider-content">
        <FilterCheckGroupOption
          v-for="option in filter.items"
          :key="option.id"
          :option="mapFilterItemToOption(option, filter.disabled)"
          :fixed-width="filter.id === 'length'"
          @apply="$emit('apply', filter.id, option.id, $event)"
        />
      </FilterCheckGroup>
    </template>
  </AccordionFilterBase>
</template>

<style lang="scss" scoped></style>
