import { type FilterGroup, type FilterMenuKeys } from '~/types/models/filter.model'

export function useFilterMenu() {
  const filterStore = useFilterStore()
  const facetStore = useFacetStore()
  const searchStore = useSearchStore()
  const modalStore = useModalStore()
  const {sidebarFiltersState} = storeToRefs(filterStore)
  const { loadingFacets } = storeToRefs(facetStore)

  const currentOpenFilter = ref<FilterMenuKeys | undefined>(undefined)

  const expandedFilters = ref<Set<FilterMenuKeys>>(
    currentOpenFilter?.value ? new Set([currentOpenFilter.value]) : new Set(),
  )

  // Take order from the Storyblok Sidebar Filters Block
  const sortedFilters = computed(() => {
    return [...sidebarFiltersState.value]
  })

  const sortedAndNotHiddenFilters = computed(() => {
    return sortedFilters.value.filter(filter => !filter.metadata.hidden)
  })

  function hide() {
    filterStore.updateRouteQueryFromState()
    currentOpenFilter.value = undefined
    modalStore.showFilterMenu = false
  }

  function clear() {
    // polite($t('clearFilters'))
    currentOpenFilter.value = undefined
    filterStore.reset()
  }

  function clearSingleFilterGroup(id: string) {
    currentOpenFilter.value = undefined
    filterStore.clearFilterGroup(id)
  }

  function toggleDropdown(key: FilterMenuKeys) {
    if (expandedFilters.value.has(key)) {
      expandedFilters.value.delete(key)
      currentOpenFilter.value = undefined
    }
    else {
      currentOpenFilter.value = key
      expandedFilters.value.add(key)
    }
  }

  watch(currentOpenFilter, (val) => {
    if (val)
      expandedFilters.value.add(val)
  })

  function isExpanded(id: FilterMenuKeys) {
    return currentOpenFilter.value === id || expandedFilters.value.has(id)
  }

  async function applyFilter(id: FilterMenuKeys, itemId: string, itemValue: any) {
    const updatedFilters = filterStore.applyFilterItem(id, itemId, itemValue)
    const updatedFiltersFromApi = await searchStore.fetchFacets({
      filters: updatedFilters,
    })

    filterStore.setSubsetFacets(updatedFiltersFromApi.facets)
  }

  const { selectedFilterGroupKeys } = storeToRefs(filterStore)

  const selectedKeys = computed<FilterMenuKeys[]>(() => selectedFilterGroupKeys.value)

  function clearFilter(filter: FilterGroup) {
    const updatedFilters = filterStore.clearFilterGroup(filter.id)

    facetStore.fetchFacetsOnly({
      filters: updatedFilters,
    })
  }

  return {
    filters: sortedAndNotHiddenFilters,
    loadingFacets,
    currentOpenFilter,
    expandedFilters,

    // Computed
    selectedKeys,

    // Methods - Filters
    clearFilter,
    applyFilter,

    // Methods - Events
    hide,
    clear,
    clearSingleFilterGroup,
    toggleDropdown,
    isExpanded,
  }
}
